import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import LockIcon from "@assets/icons/lock.svg";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  ButtonContainer,
  ContentContainer,
} from "../summary/styles/summary.style";
import { TotalZakatSummaryContainer } from "@Zakat/components/ZakatSummary/styled/styled";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioData } from "@components/PortfolioData/selectors";
import { zakatCalculator } from "@Zakat/helpers/zakat";
import {
  DEFAULT_GOLD_PRICE,
  DEFAULT_NISAB_PRICE,
} from "@Zakat/helpers/zakat/consts";
import { Text } from "@ifgengineering/component-library";
import { CurrencyContext } from "@components/CurrencyContext";
import { getUserEmail } from "@components/Login/selectors";
import {
  zakatCharityAllocationSelector,
  zakatGiftAidSelector,
  zakatTopUpSelector,
} from "@Zakat/store/selectors/zakat";
import { charityWithAllocatedValuesSelector } from "@Zakat/store/selectors/charityWithAllocatedValuesSelector";
import { fetchCharityAllocationAPI } from "@Zakat/store/actions/charityAllocation";
import { fetchZakatCharityAPI } from "@Zakat/store/actions/zakatCharity";
import styled from "styled-components";
import ZakatSummary from "@Zakat/components/ZakatSummary";
import { updateZakatAPI } from "@Zakat/store/actions/zakat";
import {
  CharityItemContainer,
  CharityName,
  CharityValueContainer,
  HeadingText,
  SmallText,
  AllocationCharityContainer,
} from "@Zakat/pages/payment/styles/payment.style";
import useAnalytics from "@helpers/useAnalytics";
import { totalAllocatedSelector } from "@Zakat/store/selectors/totalAllocatedSelector";
import { Header } from "./styles/payment.style";

const PageWrapper = styled.div`
  padding: 5vh 10vw;
`;

const PaymentSummary: React.FC = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const portfolio = useSelector(getPortfolioData);
  const charityAllocations = useSelector(zakatCharityAllocationSelector) || [];
  const charities = useSelector(charityWithAllocatedValuesSelector);
  const giftAid = useSelector(zakatGiftAidSelector);
  const zakatTopUp = useSelector(zakatTopUpSelector) || 0;
  const totalCharityPercentage = useSelector(totalAllocatedSelector);

  const { formatCurrency, silverPrice } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");

  const calculateZakat = zakatCalculator(
    DEFAULT_GOLD_PRICE,
    silverPrice,
    DEFAULT_NISAB_PRICE
  );

  const summary = calculateZakat(portfolio);
  const getCharityName = (id: string) =>
    charities.find((c) => c.id === id)?.displayName;

  const updateZakat = async () => {
    await dispatch(
      updateZakatAPI({
        data: {
          zakatableAssets: summary.zakatableAssets,
          nisab: summary.nisab,
          amountDue: summary.zakat,
        },
        email: userEmail,
      })
    );
  };

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView("zakat:paymentSummary_pageViewed");
  }, []);

  useEffect(() => {
    if (userEmail) {
      updateZakat();
      dispatch(fetchCharityAllocationAPI(userEmail));
    }

    dispatch(fetchZakatCharityAPI());
  }, [userEmail]);

  const handleConfirm = () => {
    navigate("/app/dua");
  };

  const totalAllocatedAmount = charityAllocations.reduce(
    (acc, c) => acc + (c.amount || 0),
    0
  );

  // zakat amount + top up x %charity allocation x 25% = giftAid amount
  const getGiftAidPrice = () =>
    (summary.zakat + zakatTopUp) * (totalCharityPercentage / 100) * 0.25;

  const totalPayableAmount = totalAllocatedAmount + zakatTopUp;

  const submitButtonIsActive = Boolean(totalPayableAmount);

  return (
    <PageWrapper>
      <ContentContainer>
        <Header>Complete Summary</Header>
        <ZakatSummary formatCurrency={formatCurrency} summary={summary} />
        {zakatTopUp ? (
          <TotalZakatSummaryContainer small>
            <HeadingText type="H16" color={"BLUE900"}>
              Zakat Topup
            </HeadingText>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(zakatTopUp)}
            </HeadingText>
          </TotalZakatSummaryContainer>
        ) : null}
        <TotalZakatSummaryContainer small>
          <HeadingText type="H16" color={"BLUE900"}>
            Charity Allocation
          </HeadingText>
          <AllocationCharityContainer>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(totalAllocatedAmount)}
            </HeadingText>
            <SmallText type="P16" color={"BLUE900"}>
              ({totalCharityPercentage}% of total zakat due)
            </SmallText>
          </AllocationCharityContainer>
          {charityAllocations.map((a, key) => (
            <CharityItemContainer key={key}>
              <CharityName type="P16" color={"BLUE900"}>
                {getCharityName(a.zakatCharity.id)}
              </CharityName>
              <CharityValueContainer>
                <SmallText type="P16" color={"BLUE900"}>
                  {a.percentage}% of zakat
                </SmallText>
                {a.amount && (
                  <SmallText type="P16" color={"BLUE900"}>
                    {formatCurrency(a.amount)}
                  </SmallText>
                )}
              </CharityValueContainer>
            </CharityItemContainer>
          ))}
        </TotalZakatSummaryContainer>
        {giftAid?.id && (
          <TotalZakatSummaryContainer
            color="#F9F8F3"
            borderColor="#DCC67A"
            small
          >
            <HeadingText type="H16" color={"BLUE900"}>
              Gift Aid
            </HeadingText>
            <HeadingText type="H20" color={"BLUE900"}>
              {formatCurrency(getGiftAidPrice())}
            </HeadingText>
          </TotalZakatSummaryContainer>
        )}
        <TotalZakatSummaryContainer
          color="#E6F1E4"
          borderColor="#458731"
          small
          spaceBetween
        >
          <Text type="H20" color={"BLUE900"}>
            Total to pay
          </Text>
          <Text type="H20" color={"BLUE900"}>
            {formatCurrency(totalPayableAmount)}
          </Text>
        </TotalZakatSummaryContainer>

        <ButtonContainer>
          {!submitButtonIsActive && (
            <Text type="P16" color="ERROR800">
              The payment amount cannot be £0.
            </Text>
          )}
          <FormButton
            text="Next"
            disclaimerIcon={<LockIcon />}
            leftArrowLabel="Back"
            leftArrowOnClick={() => navigate("/app/gift-aid")}
            onClick={handleConfirm}
            active={submitButtonIsActive}
          />
        </ButtonContainer>
      </ContentContainer>
    </PageWrapper>
  );
};

export default PaymentSummary;
